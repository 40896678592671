import React from 'react';
import { Table } from "react-bootstrap";
import { Link } from 'react-router-dom';

import ImageFader from '../components/ImageFader';

import ffLogo from '../images/ff_logo_use.png';
import fbtcLogo from '../images/fbtc_logo_use.png';

// import event from "../images/event_banner.png";

const Events = () => {

  const events = [
    { name: 'Strength & HIIT Training', date: 'Oct. 3rd', location: <Link to='https://secure.rec1.com/CA/san-pablo-ca/catalog'>San Pablo, CA</Link>},
    { name: 'Strength & HIIT Training', date: 'Oct. 10th', location: <Link to='https://secure.rec1.com/CA/san-pablo-ca/catalog'>San Pablo, CA</Link>},
    { name: 'Strength & HIIT Training', date: 'Oct. 17th', location: <Link to='https://secure.rec1.com/CA/san-pablo-ca/catalog'>San Pablo, CA</Link>},
    { name: 'Strength & HIIT Training', date: 'Oct. 24th', location: <Link to='https://secure.rec1.com/CA/san-pablo-ca/catalog'>San Pablo, CA</Link>}
  ];

  const images = [
    ffLogo,
    fbtcLogo
  ]

  return (
    <div className='App'>
      <header className="App-header">
        <h1>Upcoming Events</h1>
        <div>
          <ImageFader images={images} />
        </div>
      </header>
      {/* <div class="table-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Date</th>
              <th>Location</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event, index) => (
              <tr key={index}>
                <td>{event.name}</td>
                <td>{event.date}</td>
                <td>{event.location}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div> */}
      {/* <h2>Instructions</h2>
      <div className="description-container">
        When navigating to the registration page, click Fitness Classes from the available tabs, and select "Strength & HIIT Training"
      </div> */}
    </div>
  );
};

// function Item(props) {
//   return(
//     <div class="carousel-container">
//       <img
//       key={props.index}
//       src={props.item.imagePath}
//       alt={props.item.altText}
//       style={{ width: '60%', height: '600px', objectFit: 'contain' }}
//       />
//     </div>
//   )
// }

export default Events;